import Icon from './Icon';

const Check = (props) => (
  <Icon
    {...props}
    path="M32.767 56.1c-0.003 0-0.008 0-0.012 0-0.687 0-1.308-0.28-1.755-0.733l-12.767-12.767c-0.416-0.445-0.671-1.045-0.671-1.704 0-1.381 1.119-2.5 2.5-2.5 0.659 0 1.259 0.255 1.706 0.672l-0.001-0.001 11 10.933 25.467-25.433c0.449-0.46 1.074-0.745 1.767-0.745s1.318 0.285 1.766 0.745l0 0.001c0.452 0.452 0.731 1.077 0.731 1.767s-0.279 1.314-0.731 1.767v0l-27.233 27.2c-0.434 0.49-1.064 0.798-1.766 0.8h-0z"
  />
);

export default Check;
