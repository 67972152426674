/* eslint-disable import/prefer-default-export */
import { emptyObject } from '@constants/empty';
import { getStringWordsJoinedWith } from '@utils/textHelper';

export const getFormattedNewGoal = (topology) =>
  topology.relativeLink
    ? {
        ...topology,
        coursesCount:
          topology.coursesCount ||
          topology.itemCount ||
          topology.count ||
          topology.plusCourseCount ||
          topology.courseCount ||
          0,
        upcomingCoursesCount: topology.upcomingCount || 0,
        upcomingCoursesThisMonth: topology.upcomingThisMonth || 0,
        enrolledCoursesCount: topology.enrolledCount || 0,
        totalSubItem: topology.totalSubItem || 0,
        progress: topology.progress || 0,
        plusCourseCount: topology.plusCourseCount || topology.totalCount || 0,
        colorCode: topology.colorCode,
        fullName: topology.title,
        slug: topology.slug || getStringWordsJoinedWith(topology.name, '-'),
        isPracticeAvailable: topology.isPracticeSession,
        isNoTestGoal: !!topology.tags?.find(
          (tag) => tag?.name === 'NON_TEST_PREP'
        ),
        externalApps: topology.externalAppsAvailable,
        goalLevelOnboarding: {
          isAvailable: topology.isGoalOnboardingAvailable || false,
          status: topology.userOnboardingStatus || null,
          hideNudge: false
        },
        isK12: topology.isK12Goal || false
      }
    : topology;

// return true if searched value exists in option with single char misspelled
const isValidOption = (search, option) => {
  const firstChar = search[0];
  const matchedI = option.indexOf(firstChar);
  let misMatchedI = -1;

  if (matchedI < 0 || search.length > option.length) return false;

  if (matchedI !== 0) return isValidOption(search, option.substring(matchedI));

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < search.length; i++) {
    if (search[i] !== option[i]) {
      misMatchedI = i;
      break;
    }
  }

  return (
    search.substring(misMatchedI + 1) ===
    option.substring(misMatchedI + 1, search.length)
  );
};

export const isValidGoalOption = (search, goal, goalSynonyms) => {
  const goalName = goal.name;
  const goalUID = goal.uid;
  const format = /[!@#$%^*()_+=[\]\-{};'":\\|.<>?]+/g;
  const term = search.trim().toLowerCase().replace(format, '');
  const goalOption = goalName.trim().toLowerCase();

  const formattedTerm = term.replace(/\s*/g, '');
  const searchGoalSynonyms = Object.entries(goalSynonyms || emptyObject).reduce(
    (prev, [key, val]) => {
      const formattedKey = key.trim().toLowerCase().replace(format, '');
      if (formattedKey === formattedTerm) return [...prev, ...val];
      return prev;
    },
    []
  );
  return (
    searchGoalSynonyms.includes(goalUID) || isValidOption(term, goalOption)
  );
};
