// TODO: Instead of doing ({auth}) everytime we can have a seperate selector for auth and rest of data inside auth can be done using createSelector. That way we can reduce code duplication. Refer https://redux.js.org/usage/deriving-data-selectors

import {
  getIsDoubtTopologyPresent,
  getIsEvaluator,
  getIsLiveInteractionEnabled,
  getIsReviewer
} from '@api/selectors/auth';
import { emptyList, emptyObject } from '@constants/empty';

export const getIsLoggedIn = ({ auth }) => auth.isLoggedIn || false;

export const getAccessToken = ({ auth }) => auth.accessToken;

export const getChannelUID = ({ auth }) => auth.me.channels?.[0]?.uid ?? '';

export const getRefreshToken = ({ auth }) => auth.refreshToken;

export const getSearchToken = ({ auth }) => auth.me.searchJwtToken;

export const getKYCDetails = ({ auth }) => auth.me.kycDetails || emptyObject;

// #region me-data
export const getMe = ({ auth }) => auth.me;

export const getIsFetchingUserData = ({ auth }) => auth.fetchingUserData;

export const getFailedToFetchUserData = ({ auth }) =>
  auth.failedToFetchUserData;

export const getStargateJwtToken = ({ auth }) => auth.me.stargateJwtToken;

export const getJWTToken = ({ auth }) => auth.me.jwtToken;

export const getEmailId = ({ auth }) => auth.me.email;

export const getMeUid = ({ auth }) => auth.me.uid;

export const getMeUserId = ({ auth }) => auth.me.userID;

export const getFirstName = ({ auth }) => auth.me.firstName || '';

export const getLastName = (state) => getMe(state).lastName || '';

export const getFullName = (state) => getMe(state).name;

export const getThumbnail = (state) => getMe(state).thumbnail;

export const getMeUsername = ({ auth }) => auth.me.username;

export const getCanAddSpecialClass = ({ auth }) => auth.me.canAddSpecialClass;

export const getCanAddPlusCourse = ({ auth }) => auth.me.canAddPlusCourse;

export const getCountryStates = ({ auth }) => auth.countryStates;

export const getMePhone = ({ auth }) => auth.me.phone ?? '';

export const getIsEducator = (state) => getMe(state).isEducator;

export const getIsModerator = (state) => getMe(state).isModerator;

export const getMeRelativeLink = (state) => getMe(state).relativeLink;

export const getIsVendor = (state) => getMe(state).isVendor;

export const getMeCredits = (state) => getMe(state).credits ?? 0;

export const getMeCurrentEpoch = ({ auth }) => auth.me.currentEpoch;

export const getModerator = ({ auth }) => auth.me.isModerator ?? false;

export const getQuestionEditor = ({ auth }) =>
  auth.me.isQuestionEditor ?? false;

export const getExternalContentCreator = ({ auth }) =>
  auth.me.isExternalContentCreator ?? false;

export const getShowClassPracticeTab = ({ auth }) =>
  auth.me.showClassPracticeTab ?? false;

export const getContentAuditor = ({ auth }) =>
  auth.me.isContentAuditor ?? false;

export const getJwtToken = ({ auth }) => auth.me.jwtToken;

export const getLocalEmail = ({ auth }) => auth.localEmail ?? '';

export const getLocalName = ({ auth }) => auth.localName ?? '';
// #endregion

// This is getting data from auth reducer

export const getIsPartner = (state, accessControl) =>
  !getIsEducator(state) &&
  (getIsDoubtTopologyPresent(accessControl) ||
    getIsReviewer(accessControl) ||
    getIsEvaluator(accessControl) ||
    getExternalContentCreator(state) ||
    getIsLiveInteractionEnabled(accessControl) ||
    getIsVendor(state));

export const getfollowedGoals = ({ auth }) => auth.followedGoals ?? [];

export const getHasFetchedFollowedGoals = ({ auth }) =>
  auth.hasFetchedFollowedGoals;

export const getRenewalData = (state, goalUID) =>
  state.auth.renewalData[goalUID] || emptyObject;

export const getIsSubmittingFeedback = ({ auth }) => auth.isSubmittingFeedback;

export const getIsPlatformUnlocked = ({ auth }) =>
  auth.me.platformUnlockStatus?.is_platform_unlocked ?? false;

export const getReferralStats = ({ auth }) => auth.referralStats || {};

const getPlatformSubscriptionUIDs = ({ auth }) =>
  auth?.purchasedItems?.platformSubscriptionUIDs || emptyList;

export const getPlatformSubscriptionGoalUIDs = ({ auth }) =>
  auth?.purchasedItems?.platformSubscriptionGoalUIDs || emptyList;

export const isBatchSubscriptionActive = (state, goalUID) => {
  if (!goalUID) {
    return false;
  }
  return getPlatformSubscriptionGoalUIDs(state).includes(goalUID.toUpperCase());
};

export const hasActiveBatch = ({ auth }) =>
  auth?.purchasedItems?.hasActiveBatch;

export const getBatchGroupSubscriptions = ({ auth }) =>
  auth?.purchasedItems?.batchGroupSubscriptions ?? [];

export const getTotalActiveBatches = (state) =>
  getPlatformSubscriptionUIDs(state)?.length ?? 0;

export const getIsUserRegistered = ({ auth }) =>
  auth.otpOrEmailData?.is_registered ?? false;

export const getIsSubscribedToPlatformBatchGroup = (state) =>
  !!getPlatformSubscriptionUIDs(state)?.length;

export const getLocalPhoneNumber = ({ auth }) => auth.localPhoneNumber;

export const getLocalSelectedCountry = ({ auth }) => auth.localSelectedCountry;
