import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Typography from 'aquilla/core/atoms/Typography';
import BaseImage from '@base/FutureImage';
import { Spacings, mediaQuery } from '@styles/index';
import useRootPageData from '@api/hooks/home/useRootPageData';

const Image = styled(BaseImage)`
  width: 336px;
  height: 252px;
  border-radius: ${Spacings.SPACING_2B};
  ${mediaQuery.uptoTablet} {
    width: 232px;
    height: 174px;
    margin-bottom: ${Spacings.SPACING_6B};
  }
  ${mediaQuery.uptoMobile} {
    width: 100%;
    margin-top: ${Spacings.SPACING_6B};
    height: 246px;
  }
`;

const Label = styled(Typography)`
  padding-top: ${Spacings.SPACING_8B};
  padding-bottom: ${Spacings.SPACING_4B};
  color: var(--color-i-black);
  ${mediaQuery.uptoTablet} {
    font-size: 22px;
    line-height: 120%;
    padding: 0 0 ${Spacings.SPACING_2B};
  }
  ${mediaQuery.uptoMobile} {
    padding: 0 0 ${Spacings.SPACING_3B};
    ${({ $textAfterImage }) =>
      $textAfterImage && `margin-top: ${Spacings.SPACING_4B};`}
    ${({ $textAfterImage }) =>
      $textAfterImage && `padding: 0 0 ${Spacings.SPACING_2B};`}
  }
`;

const Message = styled(Typography)``;

const CardWrapper = styled.div`
  width: 336px;
  ${mediaQuery.uptoTablet} {
    width: 232px;
  }
  ${mediaQuery.uptoMobile} {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: ${({ $textAfterImage }) =>
      $textAfterImage ? 'column' : 'column-reverse'};
    text-align: ${({ $alignment }) => $alignment};
    margin-bottom: ${({ $textAfterImage }) =>
      $textAfterImage ? '32px' : '64px'};
  }
`;

const CardsWrapper = styled.section`
  display: flex;
  grid-column: span 24;
  justify-content: space-between;
  ${mediaQuery.uptoMobile} {
    padding: 0 ${Spacings.SPACING_4B};
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    & > div:last-child {
      margin-bottom: 0;
      img {
        margin-bottom: 0;
      }
    }
  }
`;

const Card = ({ card, alignment, textAfterImage }) => {
  const { urls, title = '', subTitle = '' } = card;
  return (
    <CardWrapper $alignment={alignment} $textAfterImage={textAfterImage}>
      <Image
        src={urls?.default}
        alt={title}
        width={336}
        height={252}
        layout="responsive"
      />
      <div>
        <Label variant="h3" $textAfterImage={textAfterImage}>
          {title}
        </Label>
        <Message variant="p1">{subTitle}</Message>
      </div>
    </CardWrapper>
  );
};

const SecondSlide = ({ alignment = 'center', textAfterImage = false }) => {
  const {
    rootPageData: { highlights }
  } = useRootPageData();
  return (
    <CardsWrapper>
      {(highlights || []).map((card) => (
        <Card
          key={card.title}
          card={card}
          textAfterImage={textAfterImage}
          alignment={alignment}
        />
      ))}
    </CardsWrapper>
  );
};

export default SecondSlide;

Card.propTypes = {
  card: PropTypes.objectOf(PropTypes.any).isRequired
};
