import useFetch from '@hooks/useFetch';
import { emptyList } from '@constants/empty';

export const getKey = (selectedGoalUID) =>
  selectedGoalUID
    ? `v1/uplus/subscription/subscription_goal_languages/?goal_uid=${selectedGoalUID}`
    : null;

const useGoalLanguages = (selectedGoalUID, options = {}) => {
  const key = getKey(selectedGoalUID);

  const { data: languages, ...rest } = useFetch(key, {}, options);

  return {
    key,
    languages: languages || emptyList,
    ...rest
  };
};

export default useGoalLanguages;
