// events constants
export const NOTIFY_ME_CLICKED = 'Notify - Notify Me Clicked';
export const NOTIFY_ME_REMOVED = 'Notify - Notify Me Removed';

export const eventLPSS = {
  BATCH_AND_TRIAL_CLASS_LIST: 'Section Batch Details & Demo class list',
  SCHEDULE: 'Section Schedule',
  LESSONS: 'Section Lesson',
  ABOUT_AND_EDUCATORS: 'Section About and Educators',
  FAQ_AND_TTUN_CTA: 'Section FAQ and TTUN',
  EDUCATORS: 'Section Educators',
  BATCH_AND_OVERVIEW: 'Section Batch Details & Overview'
};

export const PLATFORM_PAGE_LAND = 'Platform - Platform Page Land';
export const UPCOMING_SECTION_VIEWED = 'Batch Selection - Batch List Viewed';

export const PLATFORM_HOME_PAGE = 'Batch Group Home page';
export const SEE_ALL_CLICKED = 'See All Clicked';
export const ENROLLED_HOME_PAGE = 'Enrolled - Home Page';
export const ACTIVITY_PAGE_SCROLLED = 'Activity - Page Scrolled';
export const BATCH_SECTION_TYPE_VIEW =
  'Batch Selection - Select Batch Type Viewed';
export const BATCH_TAB_CLICKED = 'Batch - Batch Tab Clicked';

export const SAVE_SAVE_CONTENT = 'Save - Save Content';
export const SAVE_UNSAVE_CONTENT = 'Save - Unsave Content';
export const LESSON_DOWNLOAD_PDF = 'Lesson PDF Downloaded';
export const VIEW_EDUCATOR = 'Educator Profile Clicked';

export const BATCH_EXPAND_CLICKED = 'Batch Expand Clicked';
export const BATCH_SCHEDULE_EXPAND_CLICKED = 'Batch - Batch Expand Clicked';
export const UPCOMING_BATCHES = 'Batch Group Upcoming List';

// feedback events
export const SUBSCRIPTION_FEEDBACK_CARD_CLICKED =
  'Subscription Feedback Card - Clicked';
export const SUBSCRIPTION_FEEDBACK_CARD_SUBMITTED =
  'Subscription Feedback Card - Submitted';
export const SUBSCRIPTION_FEEDBACK_CARD_VIEWED =
  'Subscription Feedback Card - Viewed';
export const BATCH_GROUP_DETAIL = 'Batch Group Detail';
export const PLATFORM_SUBSCRIPTION_RATING = 'Platform Subscription Rating';
export const PLATFORM_BATCH_GROUP = 'Platform Batch Group';
