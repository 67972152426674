import OutlinedInput from '@material-ui/core/OutlinedInput';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import {
  PRIMARY_BORDER_WIDTH,
  Spacings,
  Fonts,
  breakpoints
} from '@styles/index';

const StyledP2 = styled.p`
  font-weight: ${Fonts.SEMIBOLD};
  font-size: ${Fonts.P2};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  color: ${({ color }) => color};
  margin-top: ${Spacings.SPACING_2B};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 10px;
  }
`;

const StyledTextField = styled(OutlinedInput)`
  &.MuiOutlinedInput-root {
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-divider);
    border-radius: ${Spacings.SPACING_6};
    transition: border-color 400ms ease-in-out;

    ${({ $width }) => {
      if ($width) {
        return `width: ${$width};`;
      }
    }}

    .MuiOutlinedInput-input {
      padding: ${Spacings.SPACING_4B} ${Spacings.SPACING_6B};
      ::-webkit-input-placeholder,
      ::-moz-placeholder,
      ::placeholder {
        color: var(--color-text-secondary);
        opacity: 1;
      }
    }
  }
  &.MuiOutlinedInput-multiline {
    padding: 0px;
  }
  &.MuiOutlinedInput-adornedEnd {
    padding-right: 30px;
  }
  &.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    display: none;
    pointer-events: none;
  }
  &:hover.MuiOutlinedInput-root:not(.Mui-disabled):not(.Mui-focused) {
    border-color: var(--color-text-primary);
  }
  &.MuiOutlinedInput-root.Mui-focused {
    border-color: var(--color-text-primary);
    border-width: ${PRIMARY_BORDER_WIDTH};
  }
  &.MuiOutlinedInput-root.MuiOutlinedInput-colorSecondary {
    border-color: var(--color-i-green);
  }
  &.MuiOutlinedInput-root.Mui-error {
    border-color: var(--color-i-red);
  }
  //make error style as priority
  && {
    &:hover.MuiOutlinedInput-root.Mui-error {
      border-color: var(--color-i-red);
    }
  }
  &.MuiOutlinedInput-root .MuiOutlinedInput-input.Mui-disabled {
    background: ${({ $disabledColor }) => $disabledColor};
  }
`;

const TextField = forwardRef((props, ref) => {
  const {
    width,
    helperText,
    helperTextColor,
    enterCallback,
    disabledColor,
    ...inputProps
  } = props;
  const onKeyUpWrap = (e) => {
    if (e && e.keyCode === 13) {
      e.preventDefault();
      enterCallback();
    }
  };
  return (
    <>
      <StyledTextField
        inputRef={ref}
        variant="determinate"
        $width={width}
        $disabledColor={disabledColor}
        onKeyUp={enterCallback ? onKeyUpWrap : null}
        {...inputProps}
      />
      {helperText && helperText !== '' ? (
        <StyledP2 color={helperTextColor}>{helperText}</StyledP2>
      ) : null}
    </>
  );
});

TextField.displayName = 'TextField';

TextField.propTypes = {
  width: PropTypes.string,
  helperText: PropTypes.string,
  helperTextColor: PropTypes.string,
  enterCallback: PropTypes.func,
  disabledColor: PropTypes.string,
  className: PropTypes.string
};

TextField.defaultProps = {
  width: '',
  helperText: '',
  helperTextColor: 'var(--color-i-red)',
  enterCallback: null,
  disabledColor: 'var(--color-divider)',
  className: ''
};

export default TextField;
