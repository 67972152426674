import useFetch from '@hooks/useFetch';

const useLegendsBanner = (
  options = { selector: null, fallbackData: undefined }
) => {
  const key = 'v1/feeds/banners/';
  const {
    data: legendsBanner,
    isValidating,
    ...rest
  } = useFetch(key, {}, { fallbackData: options.fallbackData });
  if (options.selector && typeof options.selector === 'function')
    return options.selector(legendsBanner || []);
  return {
    key,
    legendsBanner,
    isValidating,
    ...rest
  };
};

export default useLegendsBanner;
