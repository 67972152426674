import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import styleHelper from '@utils/styleHelper';
import { Fonts, Spacings } from '@styles/index';

const P2 = styled.p`
  font-weight: ${Fonts.REGULAR};
  font-size: ${Fonts.P2};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  margin: ${Spacings.SPACING_0B};
  color: ${({ color }) => color};
  ${({ lineClamp }) =>
    lineClamp ? styleHelper.lineClampStyle(lineClamp) : null}
`;

export default P2;

P2.propTypes = {
  color: PropTypes.string,
  lineClamp: PropTypes.number
};

P2.defaultProps = {
  color: 'var(--color-text-primary)',
  lineClamp: null
};
