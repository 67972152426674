import { simpleFetch as fetcher } from '@api/fetchers';
import { getCMSHost, getHost } from '@utils/api-helper/host';

const defaultHost = getHost();
const CMSHost = getCMSHost();

export const fetchPopularGoalsV2 = () =>
  fetcher(`${defaultHost}v2/topology/popular_goals_groups/`);

export const fetchLegendsBanner = () =>
  fetcher(`${defaultHost}v1/feeds/banners/`);

export const fetchOneCarouselFeed = () =>
  fetcher(`${defaultHost}v1/feeds/carousel`);

export const fetchRootPageData = () => fetcher(`${CMSHost}api/root-page`);

export const fetchGoalDisplayNames = () =>
  fetcher(`${CMSHost}api/goal-display-names`);

export const fetchGoalLanguages = (goalUID) =>
  fetcher(
    `${defaultHost}v1/uplus/subscription/subscription_goal_languages/?goal_uid=${goalUID}`
  );
