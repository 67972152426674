/* eslint-disable react-hooks/exhaustive-deps */
import useFetch from '@hooks/useFetch';
import { HOST_TYPE } from '@utils/api-helper/host';

const useRootPageData = (
  options = { selector: null, fallbackData: undefined }
) => {
  const key = 'api/root-page';

  const {
    data: rootPageData,
    isValidating,
    ...rest
  } = useFetch(
    key,
    { hostType: HOST_TYPE.CMS },
    { fallbackData: options.fallbackData }
  );

  if (options.selector && typeof options.selector === 'function')
    return options.selector(rootPageData || {});
  return {
    key,
    rootPageData,
    isValidating,
    ...rest
  };
};

export default useRootPageData;
