import { Spacings } from '@styles/index';
import { UPCOMING_SECTION_VIEWED } from './events';

const notificationsType = {
  UPCOMING_BATCHES: 1,
  ONGOING_BATCHES: 2
};

// number of static block sections on batch group details page
export const STATIC_BLOCK_SECTIONS = 3;

export const DEFAULT_ITEM_LENGTH_FOR_SEE_ALL = 3;
export const ITEM_TO_SHOW_IN_M_WEB = 1;
export const MAXIMUM_BATCHES_TO_SHOW_WITHOUT_SEE_ALL = 3;
export const MAXIMUM_CLASSES_TO_SHOW_WITHOUT_SEE_ALL = 4;

export const Sections = {
  DEMO_CLASSES: 'DEMO_CLASSES',
  BATCH_SCHEDULE: 'BATCH_SCHEDULE',
  EDUCATORS: 'EDUCATORS',
  FAQS: 'FAQS',
  ABOUT: 'ABOUT',
  LESSONS: 'LESSONS'
};

export const tabLabel = {
  DEMO_CLASSES: 'Demo Classes',
  BATCH_SCHEDULE: 'Schedule',
  EDUCATORS: 'Educators',
  FAQS: 'FAQs',
  ABOUT: 'Overview',
  LESSONS: 'Lessons'
};

export const defaultTabsConfig = {
  [Sections.BATCH_SCHEDULE]: {
    label: tabLabel.BATCH_SCHEDULE,
    key: Sections.BATCH_SCHEDULE,
    id: 1,
    value: 1
  },
  [Sections.LESSONS]: {
    label: tabLabel.LESSONS,
    key: Sections.LESSONS,
    id: 5,
    value: 5
  },
  [Sections.EDUCATORS]: {
    label: tabLabel.EDUCATORS,
    key: Sections.EDUCATORS,
    id: 3,
    value: 3
  },
  [Sections.ABOUT]: {
    label: tabLabel.ABOUT,
    key: Sections.ABOUT,
    id: 2,
    value: 2
  },
  [Sections.FAQS]: {
    label: tabLabel.FAQS,
    key: Sections.FAQS,
    id: 4,
    value: 4
  }
};

export const batchGroupNave = {
  home: 'batch-group/home',
  browse: 'batch-group/browse'
};

export const filterType = {
  0: 'Live&Recorded',
  2: 'Recorded',
  10: 'Live'
};

export const DEFAULT_QUERY_TYPE = 0;

export const MAXIMUM_LANGUAGES_TO_SHOW = 3;

export const batchGroupType = {
  LIVE: 1,
  RECORDED: 2,
  LIVE_AND_RECORDED: 3
};

export default notificationsType;

export const BG_OVERVIEW_TAB_ORDERING = 'bg_overview_tab_ordering';

export const SPACE_WITH_DOT = `${Spacings.SPACING_2SPACES}•${Spacings.SPACING_2SPACES}`;

export const cardCtaConfig = {
  1: {
    buttonColor: 'var(--color-red-border-focus)',
    textColor: 'var(--color-i-red)'
  },
  2: {
    textColor: 'var(--color-text-primary)',
    buttonColor: 'var(--color-base-light-0)'
  },
  3: {
    textColor: 'var(--color-text-secondary)',
    buttonColor: 'var(--color-base-light-0)'
  }
};

export const cardWidth = {
  SMALL: { web: '272px', mobile: '100%' },
  MEDIUM: { web: '340px', mobile: '100%' },
  LARGE: { web: '365px', mobile: '100%' }
};

export const BATCH_ACCESS_ENDED = 4;

export const cardType = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};

export const LANGUAGE_COUNT_FOR_CARD = 3;

export const ENTITY_SUB_TYPE = {
  1: 'Live',
  2: 'Recorded',
  3: 'Live&Recorded'
};

export const CLASS_STATE = {
  LIVE_STATE: 0,
  UPCOMING_STATE: 1,
  COMPLETELY_WATCHED_STATE: 2,
  CONTINUE_WATCH_STATE: 3,
  NOT_WATCHED_STATE: 4,
  RESCHEDULED_STATE: 5,
  CANCELLED_STATE: 6,
  ACCESS_ENDED_STATE: 7
};

export const boxShadowColorPercent = {
  0: 'rgba(235, 87, 87, 0.4)',
  70: 'rgba(235,87,87, 0)'
};

export const BatchType = { liveBatch: 1, recordedBatch: 2, hybridBatch: 3 };

export const BATCH_GROUP_TYPE_CONFIG = {
  live_batchgroups: {
    eventName: UPCOMING_SECTION_VIEWED,
    lpss: 'Platform Home',
    lpssForBatchCard: 'Platform Home - Batches',
    batchType: 'Live&Recorded'
  },
  recorded_batchgroups: {
    eventName: UPCOMING_SECTION_VIEWED,
    lpss: 'Platform Home',
    lpssForBatchCard: 'Platform Home - Batches',
    batchType: 'Recorded'
  },
  default: {
    eventName: UPCOMING_SECTION_VIEWED,
    lpss: 'Platform Home',
    lpssForBatchCard: 'Platform Home - Batches',
    batchType: 'Live'
  }
};

export const BATCH_GROUP_TYPE = {
  LIVE: 'live_batchgroups',
  RECORDED: 'recorded_batchgroups'
};

export const PLAY_ICON_DARK = 'batch-group/plat-icon-dark.svg';
export const PLAY_ICON = 'batch-group/play-icon.svg';
