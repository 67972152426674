/* eslint-disable import/prefer-default-export */
import fetch from '@api/fetchers';
import { HOST_TYPE } from '@utils/api-helper/host';

export const followGoal = (goalUID, isFollow) =>
  fetch(`v1/topology/users/${goalUID}/${isFollow ? 'pin' : 'unpin'}/`, {
    requestType: 'POST'
  });

export const fetchPinnedGoals = () =>
  fetch(
    'v1/user/me/pinned_topology/?subscribed_goals=true&include_icons=true',
    { hostType: HOST_TYPE.BACKEND_API_HOST }
  );
