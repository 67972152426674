import { forwardRef } from 'react';
import styled from '@emotion/styled';
import Link from '@base/Link/Link';
import Check from '@icon/Check';
import { Spacings } from '@styles/index';

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
  cursor: pointer;
`;

const LinkedLi = styled.li`
  cursor: pointer;
`;
const GoalImage = styled.img`
  height: ${Spacings.SPACING_8B};
  width: ${Spacings.SPACING_8B};
  margin-right: ${Spacings.SPACING_4B};
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 150%;
  color: ${({ selected }) =>
    selected ? 'var(--color-i-green)' : 'var(--color-text-primary)'};
  color: ${({ color, selected }) => selected && color};
  flex-grow: 1;
  display: block;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'initial')};
`;

const anchorStyle = 'display: flex; align-items: center; padding: 8px 16px;';

const item = (
  selected,
  links,
  option,
  displayCheck,
  onMenuClick,
  color,
  ref,
  showIcon
) => {
  const {
    icon: selGoalIcon,
    name: selGoalName,
    as,
    href,
    label,
    name,
    display_name
  } = option || {};
  const goalImage = <GoalImage src={selGoalIcon} alt={selGoalName} />;
  if (links)
    return (
      <LinkedLi onClick={onMenuClick} ref={ref}>
        <Link anchorStyle={anchorStyle} as={as} href={href}>
          {showIcon && goalImage}
          <Label selected={selected} color={color}>
            {option.label || option.name || option.display_name}
          </Label>
          {selected && displayCheck && (
            <Check size="24px" color="var(--color-i-green)" />
          )}
        </Link>
      </LinkedLi>
    );
  return (
    <StyledItem ref={ref} onClick={onMenuClick}>
      {showIcon && goalImage}
      <Label selected={selected} color={color}>
        {label || name || display_name || 'No preference'}
      </Label>
      {selected && displayCheck && (
        <Check size="24px" color="var(--color-i-green)" />
      )}
    </StyledItem>
  );
};

const MenuItem = (
  { option, selected, onClick, displayCheck, links, color, showIcon },
  ref
) => {
  const onMenuClick = () => onClick(option);
  return item(
    selected,
    links,
    option,
    displayCheck,
    onMenuClick,
    color,
    ref,
    showIcon
  );
};

export default forwardRef(MenuItem);
