/* eslint-disable react/jsx-props-no-spreading */
import Dynamic from '@base/Dynamic/Dynamic';
import Drawer from '@base/Drawer/Drawer';
import { getIsNewLoginModal } from '@stores/selectors/login';
import useLoginStore from '@stores/login/loginStore';
import { segment } from '@actions/analytics';
import useGoalEventProps from '@hooks/useGoalEventProps';
import { LOGIN_SIDE_CURTAIN_DISMISSED } from './constants';

const Login = Dynamic(() => import('@cont/Login/Login'));
const LoginDialogBox = Dynamic(() => import('@cont/Login/LoginDialogBox'));

const LoginComponent = ({ loginProps = {}, drawerProps = {} }) => {
  const IsNewLoginModal = useLoginStore(getIsNewLoginModal);
  const goalSegmentData = useGoalEventProps({});
  const { onClose, drawerState, ...restDrawerProps } = drawerProps;
  const handleClose = () => {
    segment.track(LOGIN_SIDE_CURTAIN_DISMISSED, {
      ...goalSegmentData
    });
    onClose();
  };
  if (!drawerState) return null;
  if (IsNewLoginModal)
    return (
      <LoginDialogBox
        isOpen={drawerState}
        onClose={onClose}
        loginProps={loginProps}
      />
    );
  return (
    <Drawer
      login
      drawerState={drawerState}
      onClose={handleClose}
      {...restDrawerProps}
    >
      {drawerState && <Login {...loginProps} />}
    </Drawer>
  );
};
export default LoginComponent;
