import useFetch from '@hooks/useFetch';

const usePopularGoalsGroups = (
  options = { selector: null, fallbackData: undefined }
) => {
  const key = 'v2/topology/popular_goals_groups/';
  const {
    data: popularGoalsGroups,
    isValidating,
    ...rest
  } = useFetch(key, {}, { fallbackData: options.fallbackData });

  if (options.selector && typeof options.selector === 'function')
    return options.selector(popularGoalsGroups || {});

  return {
    key,
    popularGoalsGroups,
    isValidating,
    ...rest
  };
};

export default usePopularGoalsGroups;
