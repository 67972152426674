import { getPersonName } from '@models/Item';
import { Fonts } from '@styles/index';
import { businessPlatform, scrollConstants } from '@utils/constants';
import {
  getDateDifference,
  getYear,
  getRelativeDayOrCurrentDateWithTDays,
  todayTomorrowConfig,
  getDateMonthString,
  getHoursMinutesValueFromSeconds,
  getTime,
  formatTime
} from '@utils/dates';
import isEmpty from 'lodash/isEmpty';
import {
  batchGroupType,
  cardCtaConfig,
  BATCH_GROUP_TYPE_CONFIG,
  DEFAULT_ITEM_LENGTH_FOR_SEE_ALL,
  filterType,
  ENTITY_SUB_TYPE,
  ITEM_TO_SHOW_IN_M_WEB,
  LANGUAGE_COUNT_FOR_CARD,
  CLASS_STATE,
  SPACE_WITH_DOT,
  Sections,
  defaultTabsConfig,
  tabLabel
} from './constants';

const enrollmentClockIcon = 'prodigy/enrollment-clock.svg';
const enrollmentEndedIcons = 'prodigy/enrollment-end.svg';

const getMessageWithDate = (startingText = '', date) => {
  if (!date) return null;
  const dateShort = getDateMonthString(date, false, false);
  const year = getYear(date);
  return `${startingText} ${dateShort} ${year}`;
};
const getIsEnrollmentClosed = (enrollmentEndDate) => {
  if (!enrollmentEndDate) return false;
  const dateDifference = getDateDifference(enrollmentEndDate);
  return dateDifference <= 0;
};

const getIsBatchCompleted = (batchEndDate) => {
  if (!batchEndDate) return false;
  const dateDifference = getDateDifference(batchEndDate);
  return dateDifference <= 0;
};

const getIsBatchAccessEnded = (batchAccessEndDate) => {
  if (!batchAccessEndDate) return false;
  const dateDifference = getDateDifference(batchAccessEndDate);
  return dateDifference <= 0;
};

// TODO  need to handle for PLATINUM BATCH GROUP
export const getBusinessPlatform = (goalData) => {
  if (goalData?.isPlatformGoal) return businessPlatform.UA_PLATFORM;
  return businessPlatform.UNACADEMY;
};

const getSlashPriceMessage = (date, promoText, isDiscountAvailable) => {
  if (!date || !isDiscountAvailable) return '';
  const relativeDate = getRelativeDayOrCurrentDateWithTDays(date, true);
  const isRelativeDateTodayTomorrow = todayTomorrowConfig.some(
    (day) => day === relativeDate
  );
  if (isRelativeDateTodayTomorrow) {
    return promoText || `Discount ends ${relativeDate}`;
  }
  if (relativeDate <= 7) {
    return promoText || `Discount ends in ${relativeDate} days`;
  }
  return '';
};

const getEnrollmentEndMessage = (date) => {
  if (!date) return 'N/A';
  const relativeDate = getRelativeDayOrCurrentDateWithTDays(date, true);
  const isRelativeDateTodayTomorrow = todayTomorrowConfig.some(
    (day) => day === relativeDate
  );
  if (!relativeDate) {
    return 'Enrollments have closed for this batch';
  }
  if (isRelativeDateTodayTomorrow) {
    return `Enrollment ends ${relativeDate}`;
  }
  if (relativeDate <= 7 && relativeDate > 0) {
    return `Enrollment ends in ${relativeDate} days`;
  }
  return `Enrollment ends on ${relativeDate}`;
};

const getEnrollmentStatusIcon = (date) => {
  if (!date) return '';
  const relativeDate = getRelativeDayOrCurrentDateWithTDays(date, true);
  return relativeDate < 0 ? enrollmentEndedIcons : enrollmentClockIcon;
};

const getCardSpecificDetail = (enrollmentEndDate, trialClasses) => {
  const hasTrailClass = !isEmpty(trialClasses);
  const defaultObject = {
    buttonColor: 'var(--color-red-border-focus)',
    textColor: 'var(--color-i-red)',
    icon: enrollmentClockIcon
  };
  const isEnrollmentClose = getIsEnrollmentClosed(enrollmentEndDate);

  if (isEnrollmentClose) {
    return {
      ...defaultObject,
      buttonText: 'Enrolment closed',
      icon: 'calendar-clock.svg',
      buttonColor: 'var(--color-base-light-0)',
      textColor: 'var(--color-text-secondary)'
    };
  }

  const remainingDaysCount = getDateDifference(enrollmentEndDate);

  // we will only show enroll text if the date difference is <= 7 or it does not has trail classes.
  const showEnrollMessage = remainingDaysCount <= 7 || !hasTrailClass;
  if (showEnrollMessage) {
    return {
      ...defaultObject,
      buttonText: getEnrollmentEndMessage(enrollmentEndDate)
    };
  }

  return {
    ...defaultObject,
    buttonText: trialClasses?.text || 'Check out trial classes',
    icon: 'batch-play-all.svg',
    buttonColor: 'var(--color-base-light-0)',
    textColor: 'var(--color-text-primary)'
  };
};

const getCharCount = (string = '') => {
  if (!string) return 0;
  return string.length;
};

const getFontSizeForPrice = (price, listPrice, isDiscountAvailable) => {
  if (!isDiscountAvailable) return Fonts.LARGE;
  if (getCharCount(price) > 7 || getCharCount(listPrice) > 7) {
    return Fonts.SMALL;
  }
  return Fonts.LARGE;
};

const getHref = (
  href,
  as,
  isMobile,
  data = [],
  maximumItemNeedToShowSeeAll = DEFAULT_ITEM_LENGTH_FOR_SEE_ALL
) => {
  let updatedHref = null;
  let updatedAs = null;
  if (
    (isMobile && data.length > ITEM_TO_SHOW_IN_M_WEB) ||
    data.length > maximumItemNeedToShowSeeAll
  ) {
    updatedHref = href;
    updatedAs = as;
  }
  return { updatedHref, updatedAs };
};

const getBatchTypeByFilterOptions = (filterOptions = {}) => {
  if (!!filterOptions?.value && !(filterOptions?.value in filterType)) {
    return '';
  }
  return filterType[filterOptions?.value];
};

const getInfoIcon = (isDarkModeOn = false, stateMetaInfo) => {
  const darkModeIcon =
    stateMetaInfo?.darkmode_icon || stateMetaInfo?.darkmodeIcon;
  if (isDarkModeOn && darkModeIcon) {
    return darkModeIcon;
  }
  return stateMetaInfo?.icon;
};

const getCtaState = (stateMetaInfo, isDarkModeOn = false) => {
  const hasCardState = !!stateMetaInfo?.state;
  if (!hasCardState || !(stateMetaInfo?.state in cardCtaConfig)) return null;
  const { text } = stateMetaInfo;
  const infoIcon = getInfoIcon(isDarkModeOn, stateMetaInfo);
  const { buttonColor, textColor } = cardCtaConfig[stateMetaInfo.state];
  return {
    icon: infoIcon,
    text,
    buttonColor,
    textColor
  };
};

const getLanguagesForCard = (languages = []) => {
  const languagesToShow =
    languages?.length > LANGUAGE_COUNT_FOR_CARD
      ? languages.slice(0, LANGUAGE_COUNT_FOR_CARD)
      : languages;
  return languagesToShow;
};

const getBatchConfig = (type) => {
  // it will return the default object in case type is not exist in BATCH_GROUP_TYPE_CONFIG
  if (!(type in BATCH_GROUP_TYPE_CONFIG))
    return BATCH_GROUP_TYPE_CONFIG.default;
  return BATCH_GROUP_TYPE_CONFIG[type];
};

const getJoinBatchLabel = (classType) => {
  if (
    !classType?.key ||
    !Object.values(batchGroupType).includes(classType?.key)
  )
    return 'Join batch';
  if (
    [batchGroupType.LIVE, batchGroupType.LIVE_AND_RECORDED].includes(
      classType?.key
    )
  )
    return 'Join batch';
  if (classType?.key === batchGroupType.RECORDED) return 'Buy now';
};

const getEntitySubType = (classType) => {
  if (!classType?.key || !(classType?.key in ENTITY_SUB_TYPE)) {
    return 'Live';
  }
  return ENTITY_SUB_TYPE[classType?.key];
};

const getInfoString = (lesson) => {
  // Cancelled
  const classState = lesson?.classState;
  const classType = lesson?.classType;
  if (classState === CLASS_STATE.CANCELLED_STATE) {
    return 'Cancelled';
  }

  if (classState === CLASS_STATE.LIVE_STATE) {
    return `Started at ${formatTime(lesson.startedAt || lesson.liveAt)}`;
  }
  // isRescheduled
  if (classState === CLASS_STATE.RESCHEDULED_STATE) {
    return `Rescheduled to ${getDateMonthString(
      lesson.startedAt || lesson.liveAt,
      false,
      false
    )}, ${getTime(lesson.startedAt || lesson.liveAt)}`;
  }
  // RECORDED Batch
  if (classType === batchGroupType.RECORDED) {
    // RECORDED Batch + completely watched
    if (classState === CLASS_STATE.COMPLETELY_WATCHED_STATE)
      return `Completed${SPACE_WITH_DOT}${getHoursMinutesValueFromSeconds(
        lesson?.lessonDuration
      )}`;
    return `${getHoursMinutesValueFromSeconds(lesson?.lessonDuration)}`;
  }

  if (classState === CLASS_STATE.COMPLETELY_WATCHED_STATE) {
    return `Completed${SPACE_WITH_DOT}Ended on ${getDateMonthString(
      lesson?.endsAt,
      false,
      false
    )}`;
  }

  if (classState === CLASS_STATE.NOT_WATCHED_STATE) {
    return `${`Missed ${SPACE_WITH_DOT}`}Ended on ${getDateMonthString(
      lesson?.endsAt,
      false,
      false
    )}`;
  }
  // live batch Continue Watching
  if (classState === CLASS_STATE.CONTINUE_WATCH_STATE) {
    return `Ended on ${getDateMonthString(lesson?.endsAt, false, false)}`;
  }

  // upcoming
  return `${formatTime(lesson.liveAt)}`;
};

const getInfoStringForBatchGroup = (lesson) => {
  const batchGroupInfoString = getInfoString(lesson);
  return `${batchGroupInfoString}${SPACE_WITH_DOT}${getPersonName(
    lesson?.author
  )}`;
};

const getIsMenuHide = (lesson) => {
  return !(lesson?.classState === CLASS_STATE.LIVE_STATE);
};

const getTagsConfig = (isEnrolled = false) => {
  if (!isEnrolled) {
    const tabConfig = { ...defaultTabsConfig };
    delete tabConfig[Sections.ABOUT];
    return {
      [Sections.ABOUT]: {
        label: tabLabel.ABOUT,
        key: Sections.ABOUT,
        id: 2,
        value: 2
      },
      ...tabConfig
    };
  }
  return defaultTabsConfig;
};

const getDarkModeImage = (
  isDarkModeOn = false,
  darkModeImage = '',
  image = ''
) => {
  if (isDarkModeOn && darkModeImage) {
    return darkModeImage;
  }
  return image;
};

const getShowFeedback = (batchDetails = {}) => {
  const isEnrolled = batchDetails?.isEnrolled ?? false;
  const isCardAvailable =
    batchDetails?.feedbackData?.is_card_available ?? false;
  // we will show feedback card when learner is enrolled and have is_card_available key as true.
  return isEnrolled && isCardAvailable;
};

const getTabRefs = (
  isEnrolled = false,
  { scheduleRef, lessonRef, aboutRef, educatorRef, faqsRef }
) => {
  const defaultOrderOfTabs = [
    {
      ref: scheduleRef,
      shrinkPos: scrollConstants.scroll100,
      id: Sections.BATCH_SCHEDULE
    },
    {
      ref: lessonRef,
      shrinkPos: scrollConstants.scroll100,
      id: Sections.LESSONS
    },
    {
      ref: educatorRef,
      shrinkPos: scrollConstants.scroll100,
      id: Sections.EDUCATORS
    },
    {
      ref: aboutRef,
      shrinkPos: scrollConstants.scroll100,
      id: Sections.ABOUT
    },
    {
      ref: faqsRef,
      shrinkPos: scrollConstants.scroll280,
      id: Sections.FAQS
    }
  ];
  if (!isEnrolled) {
    // here we are updating the tabs order moving ABOUT section on top
    const filteredTabs = defaultOrderOfTabs.filter(
      (tabObject) => tabObject.id !== Sections.ABOUT
    );
    return [
      {
        ref: aboutRef,
        shrinkPos: scrollConstants.scroll100,
        id: Sections.ABOUT
      },
      ...filteredTabs
    ];
  }
  return defaultOrderOfTabs;
};

// we are updating the tab ordering in case of enrolled user
// order of tabRefs should be same with activeStatuses
const getActiveStatus = (isEnrolled, activeStatuses) => {
  let [
    isAboutSectionActive,
    isScheduleSectionActive,
    isLessonSectionActive,
    isEducatorSectionActive,
    isFAQsSectionActive
  ] = activeStatuses;
  if (isEnrolled) {
    [
      isScheduleSectionActive,
      isLessonSectionActive,
      isEducatorSectionActive,
      isAboutSectionActive,
      isFAQsSectionActive
    ] = activeStatuses;
  }
  return {
    isAboutSectionActive,
    isScheduleSectionActive,
    isLessonSectionActive,
    isEducatorSectionActive,
    isFAQsSectionActive
  };
};

export {
  getMessageWithDate,
  getIsEnrollmentClosed,
  getIsBatchCompleted,
  getSlashPriceMessage,
  getEnrollmentEndMessage,
  getCardSpecificDetail,
  getEnrollmentStatusIcon,
  getCharCount,
  getFontSizeForPrice,
  getIsBatchAccessEnded,
  getHref,
  getBatchTypeByFilterOptions,
  getCtaState,
  getLanguagesForCard,
  getBatchConfig,
  getJoinBatchLabel,
  getEntitySubType,
  getInfoStringForBatchGroup,
  getIsMenuHide,
  getTagsConfig,
  getDarkModeImage,
  getShowFeedback,
  getTabRefs,
  getActiveStatus
};
