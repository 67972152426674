import useFetch from '@hooks/useFetch';

const useCarouselData = (
  options = { selector: null, fallbackData: undefined }
) => {
  const key = 'v1/feeds/carousel/';
  const {
    data: carouselData,
    isValidating,
    ...rest
  } = useFetch(key, {}, { fallbackData: options.fallbackData });
  if (options.selector && typeof options.selector === 'function')
    return options.selector(carouselData || []);
  return {
    key,
    carouselData,
    isValidating,
    ...rest
  };
};

export default useCarouselData;
