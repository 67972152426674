/* eslint-disable react-hooks/exhaustive-deps */
import useFetch from '@hooks/useFetch';
import { HOST_TYPE } from '@utils/api-helper/host';

const useGoalDisplayNames = (
  options = { selector: null, fallbackData: undefined }
) => {
  const key = 'api/goal-display-names';

  const {
    data: goalDisplayNames,
    isValidating,
    ...rest
  } = useFetch(
    key,
    { hostType: HOST_TYPE.CMS, useAuthToken: false },
    { fallbackData: options.fallbackData }
  );

  if (options.selector && typeof options.selector === 'function')
    return options.selector(goalDisplayNames || {});
  return {
    key,
    goalDisplayNames,
    isValidating,
    ...rest
  };
};

export default useGoalDisplayNames;
