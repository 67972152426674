import { emptyObject } from '@constants/empty';
import useFetch from '@hooks/useFetch';
import { HOST_TYPE } from '@utils/api-helper/host';

const useGoalSynonyms = (
  options = {
    fallbackData: undefined
  }
) => {
  const key = 'api/goal-synonyms';
  const { data, isValidating, ...rest } = useFetch(
    key,
    { hostType: HOST_TYPE.CMS },
    { fallbackData: options.fallbackData, revalidateOnMount: true }
  );
  return {
    key,
    isValidating,

    goalSynonyms: data?.data ?? emptyObject,
    ...rest
  };
};

export default useGoalSynonyms;
