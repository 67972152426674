// Segment events
export const ONBOARDING_GOAL_SELECTED = 'Onboarding - Goal Selected';
export const ONBOARDING_LANGUAGE_SELECTED = 'Onboarding - Language Selected';
export const ONBOARDING_START_LEARNING_CLICKED =
  'Onboarding - Start Learning Clicked';
export const ONBOARDING_WATCH_NOW_CLICKED = 'Onboarding - Watch Now Clicked';
export const ONBOARDING_GET_THE_APP_CLICKED =
  'Onboarding - Get The App Clicked';
export const ONBOARDING_GET_THE_APP_BANNER_DISMISSED =
  'Onboarding - Get the App Dismissed';
export const ONBOARDING_GOAL_PAGE_SCROLLED = 'Onboarding - Goal Page Scrolled';
export const ONBOARDING_GOAL_GROUP_SELECTED =
  'Onboarding - Goal Group Selected';
export const ONBOARDING_VIDEO_TESTIMONIAL_IMPRESSION =
  'Onboarding - Video Testimonial Impression';
export const ONBOARDING_VIDEO_TESTIMONIAL_PLAYED =
  'Onboarding - Video Testimonial Played';
export const ONBOARDING_GOAL_REMOVED = 'Onboarding - Goal Removed';
export const GOAL_SEARCH_SEARCH_CLICKED = 'Goal Search - Search Clicked';
export const GOAL_SEARCH_RESULT_CLICKED = 'Goal Search - Result Clicked';
export const ONBOARDING_GTP_FREE_TRY_CLICKED =
  'Onboarding - Try For Free Selected';
export const GOAL_SEARCH_NO_RESULT = 'Goal Search - No Result Found Shown';
