import { useEffect, useState } from 'react';
import { useIsMobile } from '@stores/AppCommonData';

const useOneLinkSmartScript = (
  defaultBaseLink,
  useSmartScriptWithDefaultLink = false
) => {
  const baseLink = defaultBaseLink || 'https://unacademy.onelink.me/cjVw/';
  const [oneLinkURL, setoneLinkURL] = useState(baseLink);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (defaultBaseLink && !useSmartScriptWithDefaultLink) return;
    if (window?.AF?.OneLinkUrlGenerator instanceof Function) {
      let defaultSource = 'direct';
      // Calculating Static pid value.
      defaultSource = document.referrer || 'direct';

      if (defaultSource === document.referrer && defaultSource.length) {
        // defaultSource is a URL, extract the primary name from the URL
        try {
          defaultSource = new URL(document.referrer).hostname;
        } catch (err) {
          defaultSource = document.referrer
            ?.split(/\//)
            ?.filter((fragment) => !!fragment)?.[1];
        }
      }
      // Check if defaultSource is from inside unacademy. That means the traffic is internal.
      if (defaultSource.includes('unacademy')) defaultSource = 'direct';

      const generatedSmartScriptLink = new window.AF.OneLinkUrlGenerator({
        oneLinkURL: baseLink,
        pidKeysList: ['incoming_media_source', 'utm_source'],
        pidStaticValue: defaultSource,
        campaignKeysList: ['incoming_campaign', 'utm_campaign'],
        campaignStaticValue: 'website'
      })?.generateUrl?.();
      setoneLinkURL(
        generatedSmartScriptLink + (isMobile ? '&af_adset=Mweb_to_app' : '')
      );
    }
  }, [isMobile, baseLink, defaultBaseLink, useSmartScriptWithDefaultLink]);

  return oneLinkURL;
};

export default useOneLinkSmartScript;
