import { useEffect } from 'react';
import { abProxyJWT, abVariations } from '@utils/abHelper';
import { fetchABExperimentData } from '@api/fetchers/experiments';
import cookies from '@utils/cookies';
import validateAndSetSessionId, {
  DEVICE_ID_COOKIE_NAME
} from '@utils/session/validateAndSetSessionId';
import {
  LOGIN_MODAL_EXPERIMENT,
  routesToBeInCluded
} from '@cont/Login/constants';
import {
  getSetIsLoginModalExperiment,
  getSetIsLoginModalVariation,
  getSetIsNewLoginModal
} from '@stores/selectors/login';
import useLoginStore from '@stores/login/loginStore';
import { useRouter } from 'next/router';

// Test1 - with phone number and OTP field on the same page
// Test2 - phone number on one page and OTP on other page
// Control - side curtain

const useLoginModalExperiment = () => {
  const setIsNewLoginModal = useLoginStore(getSetIsNewLoginModal);
  const setIsLoginModalExperiment = useLoginStore(getSetIsLoginModalExperiment);
  const setIsLoginModalVariation = useLoginStore(getSetIsLoginModalVariation);
  const router = useRouter();
  const { route } = router;
  useEffect(() => {
    if (routesToBeInCluded.includes(route)) {
      validateAndSetSessionId();
      const deviceID = cookies.readCookie(DEVICE_ID_COOKIE_NAME);
      fetchABExperimentData(LOGIN_MODAL_EXPERIMENT, abProxyJWT, deviceID).then(
        (res) => {
          if (res?.variation) {
            setIsNewLoginModal(res.variation !== abVariations.CONTROL);
            setIsLoginModalExperiment(res.variation !== abVariations.CONTROL);
            setIsLoginModalVariation(res.variation);
          } else {
            setIsNewLoginModal(false);
            setIsLoginModalExperiment(false);
            setIsLoginModalVariation(abVariations.CONTROL);
          }
        }
      );
    } else {
      setIsNewLoginModal(false);
      setIsLoginModalExperiment(false);
      setIsLoginModalVariation(abVariations.CONTROL);
    }
  }, [
    route,
    setIsNewLoginModal,
    setIsLoginModalExperiment,
    setIsLoginModalVariation
  ]);
};

export default useLoginModalExperiment;
