import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import NewFooter from '@layout/Footer/NewFooter';
import Dynamic from '@base/Dynamic/Dynamic';
import { mediaQuery, Spacings } from '@styles/index';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import useTTUStore from '@stores/ttu';
import { getTTUShow, getShowTTUCurtain } from '@stores/selectors/ttuSelectors';
import { showTTUOption } from '@api/fetchers/ttu';
import { noOp } from '@constants/empty';
import { useToggleable } from '@stores/toggleables';
import { LOGIN_DRAWER } from '@constants/toggleables';
import useWindowSize from '@hooks/useWindowSize';
import useSelectedGoal from '@api/hooks/topology/useSelectedGoal';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import { getIsDarkModeOn } from '@stores/selectors/darkModeStatusSelectors';
import LoginComponent from '@cont/Login/LoginComponent';
import useLoginModalExperiment from '@cont/Login/utils/hooks/useLoginModalExperiment';
import Header from '../Header/Header';

const TTUWidgetRenderer = Dynamic(() => import('@layout/TTUWidgetRenderer'));
const TTURenderer = Dynamic(() => import('@layout/TTURenderer'));

const StyledBaseFooter = styled(NewFooter)`
  background-color: var(--color-base-1);
  ${mediaQuery.uptoTablet} {
    box-shadow: ${boxShadowGenerator({ yOffset: `-${Spacings.SPACING_4B}` })};
  }
`;

const FreeLayout = ({ children, layoutOptions = {} }) => {
  const isDarkModeOn = useDarkModeStatus(getIsDarkModeOn);
  useLoginModalExperiment();
  const { query, route } = useRouter();
  const {
    isCheckoutUrl = false,
    hideFooter = false,
    hideHeader = false,
    hideTransparentMWebHeader = false,
    showFixedHeader = false,
    hideGoalSelector = false,
    hideSearch: shouldHideSearch = false,
    hideIntercom = false,
    ttuOptions = {}
  } = layoutOptions;
  const { isMobile } = useWindowSize();
  const { selectedGoal } = useSelectedGoal();
  const { isOpen: showLoginDrawer, closeElement: closeLoginDrawer } =
    useToggleable(LOGIN_DRAWER);
  const currentGoalUID = query.goalUID || selectedGoal.uid;
  const [backButton, setBackButton] = useState({
    value: false,
    fn: noOp
  });
  const handleBackPress = () => backButton.fn();

  const ttuShow = useTTUStore(getTTUShow);
  const showTTUCurtain = useTTUStore(getShowTTUCurtain);
  const [showTTU, setShowTTU] = useState(ttuShow);
  const [showTTUTooltip, setShowTTUTooltip] = useState(
    ttuOptions.openByDefault
  );
  const [ttuTriggerSource, setTTUTriggerSource] = useState('Scroll Trigger');

  const hideSearch = shouldHideSearch || isCheckoutUrl;
  const isLoginRoute = route === '/login';
  const insideApp = query.inside_app === 'true' || false;
  const transparent = showFixedHeader
    ? !showFixedHeader
    : !(hideTransparentMWebHeader && isMobile);

  function renderLogin() {
    if (!isCheckoutUrl && !isLoginRoute) {
      return (
        <LoginComponent
          loginProps={{
            setBackButton,
            backButton
          }}
          drawerProps={{
            login: true,
            drawerState: showLoginDrawer,
            onClose: closeLoginDrawer,
            showBackButton: backButton.value,
            hideCloseButton: backButton.value,
            onPressBack: handleBackPress
          }}
        />
      );
    }
    return <div />;
  }

  const onCloseTTU = () => {
    setBackButton({ value: false, fn: noOp });
    setShowTTU(false);
    showTTUCurtain(false);
    showTTUOption(currentGoalUID);
  };

  useEffect(() => {
    setShowTTU(ttuShow);
  }, [ttuShow]);

  return (
    <>
      {!hideHeader && !insideApp && (
        <Header
          transparent={transparent}
          hideSearch={hideSearch}
          hideGoalSelector={hideGoalSelector}
          isCheckoutUrl={isCheckoutUrl}
        />
      )}
      {renderLogin()}
      {children}
      {!insideApp && !hideFooter && (
        <StyledBaseFooter mode={isDarkModeOn ? 'dark' : 'light'} />
      )}
      {!isMobile && (
        <TTURenderer
          onCloseTTU={onCloseTTU}
          showTTU={showTTU}
          backButton={backButton}
          setBackButton={setBackButton}
          handleBackPress={handleBackPress}
          ttuTriggerSource={ttuTriggerSource}
          ttuOptions={ttuOptions}
        />
      )}
      {!isMobile && (
        <TTUWidgetRenderer
          showTTU={showTTU}
          setShowTTU={setShowTTU}
          showTTUTooltip={showTTUTooltip}
          setShowTTUTooltip={setShowTTUTooltip}
          ttuTriggerSource={ttuTriggerSource}
          setTTUTriggerSource={setTTUTriggerSource}
          hideIntercom={hideIntercom}
          ttuOptions={ttuOptions}
        />
      )}
    </>
  );
};

export default FreeLayout;
