import useCarouselData from '@api/hooks/home/useCarouselData';
import usePopularGoalsGroups from '@api/hooks/home/usePopularGoalsGroups';
import useLegendsBanner from '@api/hooks/home/useLegendsBanner';
import useRootPageData from '@api/hooks/home/useRootPageData';
import useGoalDisplayNames from '@api/hooks/home/useGoalDisplayNames';

const useHomeInfo = (fallbackData = {}) => {
  const { key: popularGoalsGroupsKey, popularGoalsGroups } =
    usePopularGoalsGroups({
      fallbackData: fallbackData.popularGoalsData
    });
  const { key: legendBannerKey, legendsBanner } = useLegendsBanner({
    fallbackData: fallbackData.legendBannerData
  });
  const { key: carouselDataKey, carouselData } = useCarouselData({
    fallbackData: fallbackData.carouselData
  });
  const { key: rootPageDataKey, rootPageData } = useRootPageData({
    fallbackData: fallbackData.rootPageData
  });
  const { key: goalDisplayNameskey, goalDisplayNames } = useGoalDisplayNames({
    fallbackData: fallbackData.goalDisplayNames
  });

  return {
    // SWR Keys for caching
    keys: {
      popularGoalsGroupsKey,
      legendBannerKey,
      carouselDataKey,
      rootPageDataKey,
      goalDisplayNameskey
    },
    // Data for usage
    popularGoalsGroups,
    legendsBanner,
    carouselData,
    rootPageData,
    goalDisplayNames
  };
};

export default useHomeInfo;
