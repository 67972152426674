/* eslint-disable react/jsx-key */
import React from 'react';
import styled from '@emotion/styled';
import { Spacings } from '@styles/index';
import {
  EducatorAppLinks,
  helpAndSupportLinks,
  popularGoalLinks,
  studyMaterialLinks,
  unacademyCentreLinks
} from '@layout/Footer/constants';
import Footer from 'aquilla/core/organisms/FooterLayout';
import segmentStorage from '@events/segmentStorage';
import { emptyObject } from '@constants/empty';
import { OS } from '@constants/support';
import Image from '@base/Image';
import StyleProvider from 'aquilla/styled/StyleProvider';
import Link from './Link';
// import useCentreInfo from '@api/hooks/citySpecificOfflineCentres/useCentreInfo';

const FlexLink = styled(Link)`
  display: flex;
  text-decoration: none;
  grid-gap: 10px;
  justify-content: flex-start;
`;

const LinkWithImage = styled(FlexLink)`
  margin-bottom: ${Spacings.SPACING_3B} !important;

  & > span {
    display: contents;
  }
`;

const AppImage = styled(Image)`
  border-radius: ${Spacings.SPACING_1B};
`;

const getProductsLinks = (isApppleProduct) => [
  <LinkWithImage
    key="Learner app"
    href="https://unacademy.onelink.me/vSdS/054lnfro"
  >
    <AppImage
      src="footer/learner.svg"
      alt="Learner app"
      height={24}
      width={24}
    />
    <span>Learner app</span>
  </LinkWithImage>,
  <LinkWithImage
    key="Educator app"
    href={
      isApppleProduct ? EducatorAppLinks.APP_STORE : EducatorAppLinks.PLAY_STORE
    }
  >
    <AppImage
      src="footer/educator.svg"
      alt="Educator app"
      height={24}
      width={24}
    />
    <span>Educator app</span>
  </LinkWithImage>,
  <LinkWithImage
    key="Parent app"
    href="https://play.google.com/store/apps/details?id=com.unacademy.unacademyparentapp"
  >
    <AppImage src="footer/parent.svg" alt="Parent app" height={24} width={24} />
    <span>Parent app</span>
  </LinkWithImage>
];
const getSection = (isApppleProduct) => [
  {
    title: 'Company',
    list: [
      <Link underline={false} href="/about" isRel={false}>
        About Us
      </Link>,
      <Link underline={false} href="/shikshodaya" isRel={false}>
        Shikshodaya
      </Link>,
      <Link underline={false} href="/careers" isRel={false}>
        Careers
      </Link>,
      <Link underline={false} href="https://blog.unacademy.com">
        Blogs
      </Link>,
      <Link underline={false} href="/privacy" isRel={false}>
        Privacy policy
      </Link>,
      <Link underline={false} href="/terms" isRel={false}>
        Terms and conditions
      </Link>
    ]
  },
  {
    title: 'Help & support',
    list: helpAndSupportLinks.map(({ label, href }) => (
      <Link underline={false} href={href} isRel={false}>
        {label}
      </Link>
    ))
  },
  {
    title: 'Company',
    list: getProductsLinks(isApppleProduct)
  },
  {
    title: 'Popular goals',
    list: popularGoalLinks.map(({ label, href }) => (
      <Link underline={false} href={href} isRel={false}>
        {label}
      </Link>
    ))
  },
  {
    title: 'Unacademy Centre',
    list: unacademyCentreLinks.map(({ label, href }) => (
      <Link underline={false} href={`${href}?source=footer`} isRel={false}>
        {label}
      </Link>
    ))
  },
  {
    title: 'Study material',
    list: studyMaterialLinks.map(({ label, href }) => (
      <Link underline={false} href={href} isRel={false}>
        {label}
      </Link>
    ))
  }
];

const NewFooter = ({ mode = 'dark' }) => {
  const { os: systemOS } = segmentStorage.getItem('systemInfo', emptyObject);
  const isApppleProduct = systemOS === OS.MacOS || systemOS === OS.iOS;
  const desc =
    "Unacademy is democratising education, making it accessible to all. Join the revolution, learn on India's largest learning platform.";

  return (
    <StyleProvider config={{ mode }}>
      <Footer
        sections={getSection(isApppleProduct)}
        phoneNumber="+91 8585858585"
        desc={desc}
      />
    </StyleProvider>
  );
};

export default NewFooter;
