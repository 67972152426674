import Icon from '../Icon';

const ChevronRightPlanner = (props) => {
  const { color, ...rest } = props;
  const fillColor = color || 'var(--color-text-primary)';
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...rest} viewBox="0 0 18 18">
      <path
        d="M6.75 13.5L11.25 9L6.75 4.5"
        stroke={fillColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};

export default ChevronRightPlanner;
