import MUIMenu from '@material-ui/core/Menu';
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';
import PropTypes from 'prop-types';
import boxShadowGenerator from '@utils/boxShadowGenerator';

const keyFrameOpen = (height) => keyframes`
  0% {
    max-height: 0px;
  }
  50% {
    max-height: ${height || '276px'};
    padding-bottom: 20px;
  }
  100% {
    padding-bottom: 0px;
  }
`;

const keyFrameClose = (height) => keyframes`
  0% {
    padding-bottom: 20px;
    max-height: ${height || '276px'};
  }

  100% {
    padding-bottom: 0px;
    max-height: 0px;
  }
`;

const animationOpen = (height) =>
  css`
    ${keyFrameOpen(height)} 300ms ease-in 0s
  `;

const animationClose = (height) =>
  css`
    ${keyFrameClose(height)} 300ms ease-out 0s
  `;

const StyledMenu = styled(MUIMenu)`
  &.MuiPopover-root .MuiPaper-root {
    width: ${({ width }) => width};
    border: ${({ $noBorder }) =>
      $noBorder ? 'none' : '1px solid var(--color-shadow)'};
    border-radius: 8px;
    margin-top: 10px;
    background-color: var(--color-base-1);
    box-shadow: ${boxShadowGenerator()};
    transform: none !important;
    opacity: 1 !important;
    animation: ${({ open, height }) =>
      open ? animationOpen(height) : animationClose(height)};
    max-height: ${({ height }) => height};
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    /* Chrome and IOS Safari */
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  &.MuiPopover-root .MuiMenu-list {
    padding-top: ${({ $noPadding }) => ($noPadding ? '0px' : '8px')};
    padding-bottom: ${({ $noPadding }) => ($noPadding ? '0px' : '8px')};
  }
`;

const Menu = ({
  children,
  anchorEl,
  onClose,
  position,
  width,
  height,
  noPadding,
  noBorder,
  anchorOrigin,
  transformOrigin,
  className,
  disablePortal
}) => {
  return (
    <StyledMenu
      className={className}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: position
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: position
        }
      }
      variant="menu"
      transitionDuration={200}
      width={width}
      height={height}
      $noPadding={noPadding}
      $noBorder={noBorder}
      disablePortal={disablePortal}
    >
      {children}
    </StyledMenu>
  );
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.objectOf(PropTypes.any),
  transformOrigin: PropTypes.objectOf(PropTypes.any),
  position: PropTypes.oneOf(['left', 'center', 'right']),
  width: PropTypes.string,
  height: PropTypes.string,
  noPadding: PropTypes.bool,
  noBorder: PropTypes.bool,
  anchorEl: PropTypes.oneOf([null, PropTypes.node]),
  className: PropTypes.string,
  disablePortal: PropTypes.bool
};

Menu.defaultProps = {
  anchorOrigin: null,
  transformOrigin: null,
  position: 'left',
  width: 'auto',
  height: '276px',
  noPadding: false,
  noBorder: false,
  anchorEl: null,
  className: '',
  disablePortal: false
};

export default Menu;
