import Icon from './Icon';

const KeyboardArrowDown = (props) => (
  <Icon
    {...props}
    path="M39.8 52.033c-0.003 0-0.008 0-0.012 0-0.687 0-1.308-0.28-1.755-0.733l-20-20c-0.49-0.458-0.796-1.108-0.796-1.829 0-1.381 1.119-2.5 2.5-2.5 0.721 0 1.371 0.306 1.828 0.794l0.001 0.001 18.233 18.233 18.233-18.233c0.445-0.416 1.045-0.671 1.704-0.671 1.381 0 2.5 1.119 2.5 2.5 0 0.659-0.255 1.259-0.672 1.706l0.001-0.001-20 20c-0.447 0.453-1.068 0.733-1.755 0.733-0.004 0-0.008 0-0.012 0h0.001z"
  />
);

export default KeyboardArrowDown;
